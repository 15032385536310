<!-- 成绩查询 -->
<template>
    <div class="scoreQuery">
        <div class="score">
            成绩查询
        </div>
        <!-- 表格 -->
        <el-table :data="tableData" border stripe style="width:97.9%;flex: 1;" height="650" >
            <!-- 复选按钮 -->
            <el-table-column type="selection" align="center" width="50">
            </el-table-column>
            <el-table-column type="index" label="序号" align="center" width="80">
            </el-table-column>
            <el-table-column prop="certificate_no" label="准考证号" align="center" width="200">
            </el-table-column>
            <el-table-column prop="course" label="科目" align="center" width="120">
            </el-table-column>
            <el-table-column prop="original_level" label="原等级" align="center" width="120">
            </el-table-column>
            <el-table-column prop="exam_level" label="报考等级" align="center" width="120">
            </el-table-column>
            <el-table-column prop="exam_result" label="结论" align="center" width="150">
                <template slot-scope="scope">
                    <span v-if="scope.row.exam_result==0">-</span>
                    <span v-else-if="scope.row.exam_result==1">未录入</span>
                    <span v-else-if="scope.row.exam_result==2">通过</span>
                    <span v-else-if="scope.row.exam_result==3">不通过</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="120" >
                <template slot-scope="scope">
                    <el-button icon="el-icon-view" size="mini" style="background: #18BC9C;color:#FFFFFF;"
                    @click="details(scope.$index, scope.row)">
                        详情
                    </el-button>
                </template>  
            </el-table-column>
        </el-table>
        <!--分页 -->
        <el-pagination @size-change="handleSizeChange" 
            @current-change="handleCurrentChange" :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40, 50]" :page-size="10" background
            layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>    
        <el-divider></el-divider>
        <div class="buttom">
            <div class="">
                &copy2015 深圳书画学院 考生考级报名系统 All rights reserved
            </div>
            <div class="">
                服务热线：0755-8883 0206
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                tableData:[],
                idcard:"",//身份证
                currentPage: 1,//页数
                currentLimit: 10,//条数
                total: 0,
            }
        },
       async created() {
            
           await this.$request({
               url: '/api/studentexam/info',
               method: 'POST',
               data: {
                   uid: localStorage.getItem('token')
               }
           }).then(res => {
               if (res.code == 1) {
                   console.log(res.data.idcard)
                   this.idcard = res.data.idcard
               }
               this.getList()
           })
           
        },
        activated() {
            this.getList()
            // console.log('缓存')
        },
        methods: {
            details(index,row){
                this.$router.push({
                    path: "/scoreDetails",
                    query:{
                        id:row.id
                    }
                })
            },
            getList(){//获取列表
                // console.log(this.idcard)
               this.$request({url: '/api/studentexam/scoreList', method: 'POST', data:{
                   uid: localStorage.getItem('token'),
                   idcard:this.idcard                    
               }}).then(res=>{
                   console.log(res)
                   if(res.code==1){
                       this.tableData=res.data
                   }
               }) 
            },
            handleSizeChange(val) {
            	this.currentLimit = val
            	// this.getList()
                // console.log(val)
            },
            handleCurrentChange(val) {
                this.currentPage = val
            	// this.getList()
            },
        }
    }
</script>

<style scoped="scoped">
    .scoreQuery {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .score {
        padding: 25px;
        font-weight: bold;
        font-size: 16px;
    }


    /* 表格 */
    .el-table {
        margin: 20px;
        margin-top: 0px;
    }
    
    ::v-deep .el-table__cell {
        color: #000;
        padding: 0;
        height: 35px;
    }
    /* 分页 */
    .el-pagination {
        margin:0px 20px; 
    }
    
    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active{
        background-color: #2C3E50 !important;
    }
    /* 底部 */
    .buttom{
        height: 30px;
        line-height: 30px;
        padding: 0px 25px;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
    }
</style>
